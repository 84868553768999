import React from 'react';
import CardElement from '../components/CardElement';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import Pagination from '../components/Pagination';

export const mediaQuery = graphql`
  query mediaQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "media" } } }
      limit: $limit
      skip: $skip
		) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
			      title
            publication
            url
            image
          }
        }
      }
    }
  }
`

const MediaList = ({ data, pageContext }) => {

  const posts = data.allMarkdownRemark.edges
    .map(post => {
      const element = post.node?.frontmatter
      return (
        <CardElement
          key={post.node?.id}
          image={element?.image}
          title={element?.title}
          subtitle={`(${element?.publication})`}
          url={element?.url}
        />
      )
      })

    return (
      <Layout fullMenu>
        <article id="main">
          <header>
            <h2>Featured Media</h2>
            <p>Articles featured on different media publications</p>
          </header>
          <section className="wrapper style5">
            <div className="inner posts">
              <section>
                {posts.length === 0 ?
                  <p>This section is empty.</p>
                  :
                  <div className="grid">
                    {posts}
                  </div>
                }
              </section>
            </div>
            {posts.length > 0 && <Pagination context={pageContext} slug='/media' />}
          </section>
        </article>
      </Layout>
    );
}

export default MediaList;
